<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-chat-bubble" />
      <h5 class="d-inline ml-2">New Message</h5>
      <div class="card-header-actions">
        <CButton color="dark" :to="prevRoute">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <form-summary
        v-if="!isValid"
        class="form-errors alert alert-danger mt-0"
        :validator="$v.form"
        :attributes="attributes"
      />

      <CForm class="message-form">
        <div class="d-flex flex-column">

          <div class="d-flex flex-column flex-md-row">
            <div class="form-group w-100 mr-2">
              <label class="d-block">Message Channels:</label>
              <v-select
                placeholder="Select.."
                v-model="$v.form.channels.$model"
                :options="$globalVar.messageChannels"
                :reduce="c => c.value"
                :searchable="false"
                :disabled="form.segment == 'Guest'"
                @input="getPushApps"
              />
            </div>

            <div class="form-group w-100">
              <label class="d-block">Segment:</label>
              <v-select
                placeholder="Select.."
                v-model="$v.form.segment.$model"
                :options="$globalVar.messageSegments"
                :reduce="c => c.value"
                :searchable="false"
              >
                <template #option="{ label, description }">
                  {{ label }}
                  <cite class="d-block ml-2 font-weight-normal text-muted"
                    style="font-size: 0.7em; font-size: calc(0.6em + 0.2vw);">{{ description }}</cite>
                </template>
              </v-select>
            </div>
          </div>

          <div class="d-flex flex-column flex-md-row" >
            <div class="form-group w-100" v-if="channelHasPush" :class="{ 'mr-2': form.segment === 'Leads' }">
              <label class="d-block">Push Apps:</label>
              <v-select
                placeholder="Select.."
                v-model="$v.form.apps.$model"
                label="label"
                :options="pushApps"
                :searchable="false"
                :disabled="!channelHasPush || form.segment == 'Guest'"
                multiple
              >
                <template v-slot:option="app">
                  <strong>{{ /[A-Z]/.test(app.provider) ? app.provider : app.provider.toUpperCase() }}</strong> -
                  <span>({{ app.provider == "OneSignal" ? app.domain : app.app_id }})</span>
                </template>
              </v-select>
            </div>

            <div class="form-group w-100" v-if="form.segment === 'Leads'">
              <label class="d-block">Reference Restaurant for Leads:</label>
              <v-select
                placeholder="Select.."
                v-model="$v.form.data['restaurant_id'].$model"
                label="label"
                :options="restaurants"
                :reduce="r => r.value"
                :searchable="false"
                :disabled="form.segment !== 'Leads'"
                :loading="loading && restaurants.length == 0"
              />
            </div>
          </div>



          <!-- Settings -->
          <section class="alert alert-dark p-2 my-3">
            <div class="form-group w-100 mb-0">
              <h5>Settings</h5>

              <!-- Use Voucher (optional) -->
              <div v-if="isApplicableCampaign" class="form-group w-100">
                <label class="d-block">Use Voucher (optional):</label>
                <v-select
                  :placeholder="vouchers.length == 0 ? 'Search..' : 'Select or search..'"
                  v-model="$v.form.voucher.$model"
                  :options="vouchers"
                  label="code"
                  :searchable="true"
                  :loading="loading && vouchers.length == 0"
                  @search="searchVouchers"
                >
                  <template v-slot:option="v">
                    {{ v.code }}
                    <br />
                    <cite class="small">{{ v.name }}</cite>
                  </template>
                  <span slot="no-options">Type to search vouchers..</span>
                </v-select>
              </div>

              <!-- Custom Link (optional) -->
              <div v-if="channelHasPush" class="form-group w-100">
                <label class="d-block">Custom Link (optional):</label>
                <div class="d-flex flex-column flex-md-row">
                  <CInput
                    class="w-100 mb-1 mb-md-0 mr-2"
                    placeholder="URL"
                    :value.sync="$v.form.data['custom_link']['url'].$model"
                  />
                  <CInput
                    class="w-100 mb-0"
                    placeholder="Title"
                    :value.sync="$v.form.data['custom_link']['title'].$model"
                  />
                </div>
              </div>

              <div class="d-flex flex-column flex-md-row">
                <!-- Put message in inbox -->
                <div class="form-group w-100 mr-2">
                  <label class="d-block">Put Inbox:</label>
                  <div class="d-flex flex-gap-3">
                    <CSwitch
                      :checked.sync="$v.form.put_inbox.$model"
                      labelOn="YES"
                      labelOff="NO"
                      color="success"
                      shape="pill"
                    />
                    <div v-if="form.put_inbox" class="d-flex align-items-baseline flex-gap-1">
                      <label>Active Until:</label>
                      <CInput
                        type="date"
                        :value.sync="$v.form.active_until.$model"
                        :min="new Date().toISOString().slice(0,10)"
                      />
                    </div>
                  </div>
                </div>

                <!-- In-App Message -->
                <div v-if="channelHasPush" class="form-group w-100">
                  <label class="d-block">In-App Message:</label>
                  <div class="d-flex flex-gap-3">
                    <CSwitch
                      :checked.sync="$v.form.data['in_app'].$model"
                      labelOn="YES"
                      labelOff="NO"
                      color="success"
                      shape="pill"
                    />

                    <div v-if="form.data['in_app'] === true" class="d-flex align-items-end flex-gap-1">
                      <CInputCheckbox
                        custom
                        label="Do not send as Push Notification"
                        :checked.sync="$v.form.data['disable_push_notification'].$model"
                      />
                    </div>
                </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Message -->
          <div class="d-flex flex-column flex-md-row message">
            <CSpinner color="primary" size="sm" v-if="!languages.length" />

            <CTabs v-else>
              <CTab v-for="(lang, index) in languages" :key="index" :title="lang.label" class="tab-item">
                <CInput
                  class="w-100 mb-2"
                  :placeholder="`${lang.label} title`"
                  :value.sync="$v.form.title[lang.value].$model"
                  :isValid="lang.value === 'nl' ? checkIfValid('title.nl') : null"
                />

                <div class="form-group message-editor">
                  <editor
                    v-model="$v.form.message[lang.value].$model"
                    api-key="hr96yvu80sl0l683adp0offp2wyhhcsu2n23iwl38tvwed63"
                    :init="{
                      plugins: 'wordcount,visualchars,table',
                      toolbar_mode: 'sliding',
                      toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                      tinycomments_mode: 'embedded',
                    }"
                  />
                </div>

                <div class="d-flex align-items-center justify-content-between">
                  <!-- <span>Characters: {{ form.message[lang.value].length }}</span> -->

                  <CDropdown color="link" :caret="false">
                    <template #toggler-content>
                      <div class="d-flex flex-gap-1 align-items-center">
                        <CIcon name="cil-plus" /> Add variable
                      </div>
                    </template>

                    <CDropdownItem v-for="(v, index) in variables" :key="index" @click="addVariable(lang.value, v.field)">
                      {{ v.label }}
                    </CDropdownItem>
                  </CDropdown>
                </div>
              </CTab>
            </CTabs>
          </div>
        </div>
      </CForm>
    </CCardBody>

    <CCardFooter class="sticky-bottom">
      <div class="d-flex align-items-center flex-gap-2">
        <CDropdown caret split color="primary" size="lg">
          <template #toggler-content>
            <div @click="send()" :class="{ 'disable-item': sending }">
              🚀 Send
            </div>
          </template>

          <CDropdownItem @click="showScheduleSendModal = true">
            <div class="d-flex align-items-center flex-gap-1">
              🗓️ Schedule send
            </div>
          </CDropdownItem>
          <CDropdownItem @click="send({ isTest: true })">
            <div class="d-flex align-items-center flex-gap-1">
              🧪 Send Test
            </div>
          </CDropdownItem>
        </CDropdown>

        <CButton
          class="ml-auto"
          variant="ghost"
          color="danger"
          @click="resetForm"
        >
          <u>Reset Form</u>
        </CButton>
      </div>
    </CCardFooter>

    <CModal class="schedule-send-modal" title="Pick date & time" color="white" :show.sync="showScheduleSendModal">
      <div class="d-flex">
        <v-date-picker
          mode="dateTime" is24hr :minute-increment="5"
          v-model="scheduledTime"
          :disabled-dates="{
            end: new Date().setDate(new Date().getDate() - 1),
          }"
        />

        <div v-if="scheduledTime" class="lead p-3 w-100">
          <span class="d-block border-bottom pb-2 mb-4">{{ momentScheduledTime.format("MMM Do YY") }}</span>
          <span class="d-block border-bottom pb-2 mb-4">{{ momentScheduledTime.format('LT') }}</span>
          <span v-if="momentScheduledTime.isSameOrBefore(new Date())" class="d-block text-danger">Invalid date & time</span>
          <span v-else class="d-block">* {{ momentScheduledTime.fromNow() }}</span>
        </div>
      </div>
      <template #footer>
        <CButton @click="showScheduleSendModal = false" color="link">Cancel</CButton>
        <CButton @click="scheduleSend"
          :disabled="scheduledTime === null || momentScheduledTime.isSameOrBefore(new Date())"
          color="primary"
          shape="pill">
          Schedule send
        </CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate"
import { required, requiredIf } from "vuelidate/lib/validators"
import _debounce from 'lodash/debounce';
import Editor from '@tinymce/tinymce-vue';
import { DatePicker } from 'v-calendar';

const voucherMustBeCompatibleWithRestaurant = (voucher, form) => {
  return voucher && form.data['restaurant_id']
    ? voucher.resto_id === null || voucher.resto_id === form.data['restaurant_id']
    : true;
};

export default {
  name: "NewMessage",
  components: {
    'editor': Editor,
    'v-date-picker': DatePicker,
  },
  data() {
    return {
      form: {},

      pushApps: [],
      languages: [],
      vouchers: [],
      restaurants: [],

      vars: [
        { field: "first_name", label: "User First Name" },
        { field: "email", label: "Email" },
        { field: "user.point", label: "User Point", segments: ["User", "UsersWithOrders", "HighPointUsers"] },
        { field: "nearby_restaurant_count", label: "Nearby Restaurant Count", segments: ["UsersWithOrders"] },

        { field: "restaurant_name", label: "Restaurant: Name", type: "restaurant", segments: ["Restaurant", "Leads"] },
        { field: "restaurant_city", label: "Restaurant: City", type: "restaurant", segments: ["Restaurant", "Leads"] },
      ],
      campaignVars: [
        { field: "voucher_code", label: "Voucher: Code" },
        { field: "discount", label: "Voucher: Discount" },
        { field: "valid_until", label: "Voucher: Valid Until" },
      ],

      attributes: {
        channels: 'Message channels',
        apps: 'Push apps',
        'title.nl': 'Title in Nederlands',
        'message.nl': 'Message in Nederlands',
        voucher: 'Voucher',
        data: {
          restaurant_id: "Reference restaurant for leads"
        }
      },

      sending: false,
      loading: false,
      prevRoute: null,
      scheduledTime: null,
      showScheduleSendModal: false,
    }
  },

  // Get previous page
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  mixins: [validationMixin],
  validations: {
    form: {
      channels: { required },
      apps: {
        required: requiredIf(function () {
          return this.channelHasPush;
        }),
      },
      segment: {},
      title: {
        nl: { required },
        fr: {},
        en: {},
      },
      message: {
        nl: { required },
        fr: {},
        en: {},
      },
      voucher: {
        voucherMustBeCompatibleWithRestaurant
        // required: requiredIf(function () {
        //   return !!this.form.campaign;
        // }),
      },
      data: {
        restaurant_id: {
          required: requiredIf(function () {
            return this.form.segment === 'Leads';
          }),
        },
        custom_link : {
          url : {},
          title: {},
        },
        in_app: {},
        disable_push_notification: {},
      },
      put_inbox: {},
      active_until: {},
    }
  },

  computed: {
    ...mapGetters(["errors"]),
    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    // channelHasPush() { return this.form.channels.includes('push'); },
    channelHasPush() { return this.form.channels == 'push'; },
    isApplicableCampaign() { return ["User", "Leads", "Guest", "NoRecentOrdersUsers", "HighPointUsers"].includes(this.form.segment) },
    variables() {
      const vars = this.vars.filter(v => !v.segments || v.segments.includes(this.form.segment));
      return this.form.voucher
        ? [
          ...vars,
          ...this.campaignVars
        ]
        : vars;
    },
    momentScheduledTime() { return this.moment(this.scheduledTime) },
  },

  watch: {
    form: {
      handler: function (val) {
        localStorage.setItem('user-message', JSON.stringify(val));
      },
      deep: true,
    },

    'form.voucher': async function (newVal, oldVal) {
      const confirmReset = async () => {
        const confirm = await this.mSwal.fire({
          title: "Use the default campaign template?",
          icon: "question",
        });
        return confirm.isConfirmed;
      };

      if (!oldVal && newVal) {
        if (await confirmReset()) {
          await this.getCampaignMailContents();
        }
      }
    },

    'form.segment': async function (newVal, oldVal) {
      if (!this.isApplicableCampaign) {
        this.form.voucher = null;
      }

      if (newVal === 'Leads') {
        await this.getAllRestaurants();
      } else {
        this.form.data['restaurant_id'] = null;
      }

      if (newVal === 'Guest') {
        // this.form.channels = ["push"];
        this.form.channels = "push";
        await this.getPushApps();

        this.form.apps = this.pushApps.filter(app => app.app_id == 'fcm-menute-enduser');
      }
    },

    'form.put_inbox': function (newVal, oldVal) {
      if (!newVal) {
        this.form.active_until = null;
      }
    },

    'form.data.in_app': function (newVal, oldVal) {
      if (!newVal) {
        this.form.data.disable_push_notification = false;
      }
    },
  },

  async beforeMount() {
    this.searchVouchers = _debounce(async (val) => {
      await this.getVouchers(val);
    }, 500);

    this.form = this.getForm();

    if (this.channelHasPush) {
      await this.getPushApps();

      if (this.form.apps !== null && typeof this.form.apps === "string") {
        const apps = this.form.apps.split(',').map(Number);
        this.form.apps = this.pushApps.filter(function (app) {
          return apps.includes(app.id);
        });
      }
    }
  },

  async mounted() {
    this.loading = true;
    await this.getAllLanguages();
    this.loading = false;
  },

  methods: {
    checkIfValid(fieldName) {
      const fields = fieldName.split(".");
      const field = fields.length > 1
        ? this.$v.form[fields[0]][fields[1]]
        : this.$v.form[fieldName];

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "");
    },

    getForm() {
      var form = {
        // "channels": ["email"],
        "channels": "push",
        "apps": [],
        "segment": "User",
        "title": {
          "nl": "",
          "fr": "",
          "en": ""
        },
        "message": {
          "nl": "",
          "fr": "",
          "en": ""
        },
        "voucher": null,
        "put_inbox": true,
        "active_until": null,
        "data": {
          "restaurant_id": null,
          "custom_link": {
            "url": null,
            "title": null
          },
          "in_app": false,
          "disable_push_notification": false,
        }
      };

      return JSON.parse(localStorage.getItem('user-message')) || form;
    },

    async resetForm() {
      const confirmReset = async () => {
        const confirm = await this.mSwal.fire({
          title: "The form will be reset. Are you sure you want to continue?",
          icon: "warning",
        });
        return confirm.isConfirmed;
      };

      if (await confirmReset()) {
        localStorage.removeItem('user-message')
        this.form = this.getForm();
      }
    },

    addVariable(lang, field) {
      this.form.message[lang] += `{{${field}}}`
    },

    isFormIncludeVar(variable) {
      var { title, message } = this.form;
      return Object.values({ title, message }).some(langs =>
        Object.values(langs).some(text =>
          text.includes("{{" + variable + "}}")
        )
      );
    },

    isFormIncludeCampaignVars() {
      var { title, message } = this.form;

      return Object.values({ title, message }).some(langs =>
        Object.values(langs).some(text =>
          this.campaignVars.some(v => text.includes("{{" + v.field + "}}"))
        )
      );
    },

    isFormIncludeRestaurantVars() {
      var { title, message } = this.form;
      const restaurantVars = this.vars.filter(v => v.type == "restaurant");

      return Object.values({ title, message }).some(langs =>
        Object.values(langs).some(text =>
          restaurantVars.some(v => text.includes("{{" + v.field + "}}"))
        )
      );
    },

    async send({ executionTime = null, isTest = false } = {}) {
      this.form.data.in_app ||= !!(this.form.data.custom_link.url);

      if (!this.isValid) {
        this.$v.$touch();
        return;
      }

      if (!["User", "UsersWithOrders", "HighPointUsers"].includes(this.form.segment) && this.isFormIncludeVar("user.point")) {
        this.$toast.error(`User point variable can only be used in the "User", "Users with Orders" and "High Point Users" segments.`);
        return;
      }

      if (!["UsersWithOrders"].includes(this.form.segment) && this.isFormIncludeVar("nearby_restaurant_count")) {
        this.$toast.error(`Nearby restaurant count variable can only be used in the "Users with Orders" segment.`);
        return;
      }

      if (!["Restaurant", "Leads"].includes(this.form.segment) && this.isFormIncludeRestaurantVars()) {
        this.$toast.error(`Restaurant variables can only be used in the "Restaurant" and "Leads" segments when a restaurant is selected.`);
        return;
      }

      if (!this.form.voucher && this.isFormIncludeCampaignVars()) {
        this.$toast.error(`The message contains campaign variables even though no voucher is selected!`);
        return;
      }

      if (this.form.data['restaurant_id'] && this.form.voucher?.resto_id === null) {
        const confirmReset = await this.mSwal.fire({
          text: "Do you still want to continue?",
          title: "There are no restaurant restrictions for the selected voucher!",
          icon: "warning",
          confirmButtonText: "Continue",
          cancelButtonText: "Cancel"
        });

        if (confirmReset.isDismissed) return;
      }

      const confirm = await this.mSwal.fire({
        customClass: {
          container: 'message-swal2-container',
        },
        title: `Confirm ${isTest ? 'Test' : ''} Message Sending`,
        html: `
          <div class="alert alert-info lead">
            🕑 Time to send: <b>${executionTime ? this.momentScheduledTime.format('MMMM Do YYYY, HH:mm') : 'Now'}</b>
          </div>
          <ul>
            <li><b>&#10148; Message Channels:</b> ${this.form.channels}</li>
            <li><b>&#10148; Push Apps:</b> ${this.form.apps?.pluck('label')}</li>
            <li><b>&#10148; Segment:</b> ${this.form.segment}</li>

            <li>&nbsp;</li>
            <li><b>&#10148; Title:</b>
            <li>[NL] ${this.form.title.nl}</li>
            <li>[FR] ${this.form.title.fr}</li>
            <li>[EN] ${this.form.title.en}</li>

            <li>&nbsp;</li>
            <li><b>&#10148; Messages:</b>
            <li><u>[NL]</u> ${this.form.message.nl}</li>
            <li><u>[FR]</u> ${this.form.message.fr}</li>
            <li><u>[EN]</u> ${this.form.message.en}</li>
          </ul>
        `,
        icon: "question",
        input: "checkbox",
        inputValue: 0,
        inputPlaceholder: "I approve the bulk sending of this message.",
        confirmButtonText: `Send`,
        inputValidator: (result) => {
          return !result && "Please check the confirmation box for this message to be sent!";
        }
      });

      if (confirm.isConfirmed) {
        const url = this.$backend.CRM_MESSAGES.SEND;
        this.form.data["is_test"] = isTest;
        this.sending = true;

        try {
          const { data } = await this.$axios.post(url,
            {
              ...this.form,
              // channels: this.form.channels.join(','),
              channels: this.form.channels,
              apps: this.form.apps?.pluck('id').join(','),
              voucher: this.form.voucher?.id,
              scheduled_time: executionTime ? this.momentScheduledTime.format('YYYY-MM-DD HH:mm') : null
            });

          this.$toast.success(`${data.message}`);
          if (isTest == false) {
            localStorage.removeItem('user-message')
            this.$router.push({ name: "Message List" });
          }
        } catch (error) {
          this.sending = false;
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        } finally {
          this.sending = false;
        }
      }

    },

    async scheduleSend() {
      this.showScheduleSendModal = false;
      this.send({ executionTime: this.scheduledTime });
    },

    async getPushApps() {
      if (!this.channelHasPush) {
        this.form.apps = [];
        this.form.data["custom_link"] = { "url": null, "title": null };
        this.form.data["in_app"] = false;
        this.form.data["disable_push_notification"] = false;
        return;
      }

      !this.pushApps.length && await this.$axios.get(this.$backend.PUSHER.APP_LIST)
        .then(({ data }) => {
          this.pushApps = data;
        });
    },

    async getAllLanguages() {
      this.languages = JSON.parse(localStorage.getItem('languages')) || [];

      !this.languages.length && await this.$axios.get(this.$backend.SETTINGS.GET_LANGUAGES)
        .then(({ data }) => (this.languages = data))
        .then(() => localStorage.setItem('languages', JSON.stringify(this.languages)))
    },

    async getCampaignMailContents() {
      await this.$axios.get(this.$backend.CAMPAIGNS.MAIL_CONTENT)
        .then(({ data }) => {
          this.form.message = data.message;
          this.form.title = data.title;
        });
    },

    async getVouchers(val) {
      if (val.length < 3) {
        return;
      }

      this.loading = true;

      var params = {
        code: val,
        restaurant_id: this.form.data['restaurant_id']
      };

      await this.$axios.get(this.$backend.VOUCHERS.LIST, { params: params })
        .then(({ data }) => {
          this.vouchers = data;
          this.loading = false;
        });
    },

    async getAllRestaurants() {
      this.loading = true;
      await this.$axios.get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then(({ data }) => {
          this.restaurants = data;
        });
      this.loading = false;
    },
  }
};
</script>

<style>
form.message-form .form-control,
form.message-form option,
form.message-form .vs__search,
form.message-form .vs__dropdown-menu,
form.message-form .vs__selected {
  font-size: 1.125rem;
  color: #9b0a0a;
  font-weight: 500;
}

form.message-form .vs__dropdown-toggle {
  padding: 2px 0 6px;
}

form.message-form .message > div:not(.spinner-border) {
  margin-top: 1rem;
  width: 100%;
}

form.message-form .tab-content {
  padding: 0.5rem 0.5rem 0 0;
}

form.message-form label.c-switch {
  margin-top: 0.5rem;
}

.message-swal2-container .swal2-html-container {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
}
.message-swal2-container .swal2-html-container > ul {
  padding-left: 0rem;
}
.message-swal2-container .swal2-html-container > ul li {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
}

.message-editor {
  margin-bottom: 0;
}
.message-editor .tox.tox.tox-tinymce {
  min-height: 640px;
}

.schedule-send-modal .vc-container {
  box-shadow: none !important;
  border: none !important;
}
</style>
